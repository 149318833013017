.import_customer_wrapper {
  padding: 0px 14px;
}

.customer_wrapper {
  padding: 0px 24px;
}

.flex_row {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-top: 10px;
}

.stats_card {
  border-top: 1px solid #efefef;
  padding-top: 8px;
  box-shadow: 0px 1px 1px rgba(73, 58, 177, 0.08);
}

.mb_12 {
  margin-bottom: 12px;
}

.listing_detail_container {
  gap: 75px;
  display: flex;
}

.topWrapper {
  display: flex;
  justify-content: space-between;
}
.cardsWrapper {
  display: inline-flex;
  gap: 10px;
  width: 65%;
  overflow: auto;
  padding: 20px;
  padding-top: 0px;
  flex-grow: 1;
}
.topDivWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.no_schedule_div {
  border-top: 1.5px solid #e7e6e4;
  font-family: "Helvetica Neue,roboto";
  font-style: normal;
  font-weight: 600;
  font-weight: 600;
  font-size: 14px;
  padding-top: 10px;
}

.no_schedule_div p {
  color: #ffab00;
  font-family: "Helvetica Neue,roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.listing_image {
  width: 40%;
  height: 276px;
  object-fit: cover;
}

.stats_card_desktop_wrap {
  display: flex;
  gap: 14px;
}
.stats_card_desktop {
  width: 25%;
  box-shadow: 0px 1px 1px rgba(73, 58, 177, 0.08);
}

.listing_empty_wrapper {
  padding-top: 24px !important;
}

.offering_image {
  width: 139px;
  height: 112px;
  border-radius: 12px;
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action_wrapper {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  flex-grow: 1;
}

.upload_sheet_container {
  background-color: #f6f5fd;
  border: 2px dotted #493ab1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 161px;
  width: 692px;
}

.upload_container {
  width: 692px;
}

.uploader_title {
  padding: 10px 20px;
  border-bottom: 1px solid #e4e8f0;
}

.desktop_detail_modal {
  min-width: 500px;
}

.moreContentModal {
  padding: 24px !important;
}

.detail_modal_header > h2 > div,
.mobile_detail_header {
  height: 81px !important;
}

.status_modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 320px;
  padding: 48px 16px;
}

.error {
  color: #ba0c0c;
}

.success {
  color: #1f9d08;
}

.bottom_margin {
  margin-bottom: 25px !important;
}

.listing_container {
  max-width: 60%;
  /* width: 60%; */
}

.more {
  margin-top: 8px;
}

.table_heading {
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0;
}

.mail_all {
  position: absolute;
  right: 0;
}

.viewAnswers {
  color: #007bff;
  cursor: pointer;
}

.drawerFooter {
  padding: 16px;
  border-top: 1.5px solid #e7e6e4;
  margin-top: 12px;
}

.viewAnswers {
  color: #007bff;
  cursor: pointer;
}

.week_modal {
  width: auto;
  white-space: nowrap;
}

.options_modal {
  background-color: #fff;
  box-shadow: 0px 2px 4px gray;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  padding: 5px 0;
  align-self: center;
  justify-content: center;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  overflow: auto;
  outline: none;
}

.modal {
  background-color: white;
  padding: 15px 20px 15px 20px;
}

.modal_title {
  font-weight: 600;
  font-size: 24px;
}

.modal_subtitle1 {
  font-weight: 500;
  font-size: 20px;
  margin: 8px 0 4px;
}

.modal_subtitle2 {
  font-size: 15px;
  letter-spacing: 0.7px;
}

.modal_question {
  color: #555;
}

.modal_answer {
  font-size: 19px;
  margin: 4px 0 8px;
}

.modal_close {
  float: right;
  font-size: 36px;
  font-weight: 400;
  top: 0;
  line-height: 1em;
  cursor: pointer;
  color: #555;
}

.week_modal {
  width: auto;
  white-space: nowrap;
}

.options_modal {
  background-color: #fff;
  box-shadow: 0px 2px 4px gray;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  padding: 5px 0;
  align-self: center;
  justify-content: center;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  overflow: auto;
  outline: none;
}

.modal_loading {
  font-size: 40px;
  color: #777;
  margin: 60px 0 100px;
  text-align: center;
}

.hint {
  font-size: 14px;
  color: #666666;
  width: 100%;
  line-height: 1.29;
  letter-spacing: normal;
  margin: 10px 0 0 0;
}

.participants_flex_row {
  display: flex;
  flex-direction: row;
  width: 80%;
  padding-left: 10px;
  margin-top: 10px;
}

.bold_black_large_text {
  font-size: 24px;
  color: #212121;
  margin: 0 0 25px 0;
  font-weight: 600;
}

.card {
  padding: 20px 15px 10px 15px;
  margin: 15px 15px 15px 5px;
  width: auto;
}

.label {
  min-width: 25%;
}

.sticky {
  padding-bottom: 20px;
  position: sticky;
  padding: 45px 0 0 0;
  top: 0px;
  z-index: 100;
  background-color: #ffffff;
}

.clear_filter {
  margin: 0;
  cursor: pointer;
}

.row {
  margin: 15px 0 30px 0;
}

.bold_black_normal_text {
  font-size: 16px;
  color: #212121;
  margin: 0 25px 0px 0;
  font-weight: 700;
}

.normal_black_text {
  font-size: 16px;
  color: #212121;
  margin: 0 25px 0px 0;
  font-weight: normal;
  word-wrap: break-word;
}

.whatsapp_icon {
  width: 16px !important;
  margin-left: 2px;
  color: #6c5dd3 !important;
  padding-bottom: 3px;
}

.unselected_filter {
  color: #6c5dd3 !important;
}

.selected_filter {
  background-color: #6c5dd3 !important;
  border: 1px solid #fff;
  color: #fff;
}

.whatsapp_number {
  white-space: pre;
}

.link_text {
  font-size: 16px;
  margin: 0 25px 0px 0;
  font-weight: normal;
  word-wrap: break-word;
}

.filterMobile {
  margin-top: 10px !important;
}
.searchIcon {
  display: block;
  margin-right: -10px;
}

.searchIcon svg {
  width: 50px;
}

.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.td,
.th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.table_header {
  font-size: 24px;
  color: black;
  margin: 0 0 25px 0;
  font-weight: 600;
}

.delete_title {
  color: #df635d;
  cursor: pointer;
}

.buttonfilter {
  display: block;
  border-radius: 10px;
  margin: 20px;
  padding: 5px 10px;
  border: 0.5px solid #f0eefb !important;
  cursor: pointer;
  background-color: #f0eefb;
}

.inactivebutton {
  color: red !important;
}

.filterButtonContainer {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  position: relative;
}

.highpaying {
  color: green !important;
}
.newcustomer {
  color: #6c5dd3;
}
.widthAdjust {
  width: 40%;
}

.cursorPointer {
  cursor: pointer;
}

.noCursor {
  cursor: default !important;
}

.bottomDiv,
.newBottomDiv {
  display: none;
}

.group_svg {
  font-size: 32px !important;
}

.WhatsappButton {
  background: #47c757 !important;
  border-radius: 5px;
  text-align: center;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  justify-content: flex-start;
}

.WhatsappButton a {
  color: #ffffff;
}

.group_svg path {
  fill: inherit;
}

.card_title {
  font-size: 14px;
}

.nav_wrap_customer_list {
  padding: 20px 24px;
  border-bottom: 1.5px solid #e7e6e4;
  display: flex;
  align-items: center;
  gap: 12px;
}

.headerContainer {
  height: 75.23px;
  border-bottom: 1px solid #e7e6e4;
}

.nav_back_btn,
.nav_back_btn svg {
  color: #272522;
  padding: 0 !important;
}

.page_title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #272522;
}

.action_text {
  color: #493ab1;
  text-decoration: underline;
  text-align: left;
}

.action_text:hover {
  cursor: pointer;
}

.schedule_text {
  color: #493ab1;
  text-decoration: underline;
  text-align: left;
  display: flex;
  gap: 5px;
}

.schedule_text:hover {
  cursor: pointer;
}
.stats_wrapper {
  display: flex;
  gap: 15px;
  overflow-x: scroll;
  width: calc(100vw - 42px);
  padding-bottom: 8px;
}

.statsCard {
  min-width: 122px;
  width: 25%;
}

.mobile_secondary_action {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.85);
}
.mobile_footer {
  padding-right: 9.4px !important;
}
.mobile_footer_1 {
  background-color: #fbebea !important;
}
.mobile_footer_2 {
  background-color: #fff7e5 !important;
}
/* .mobile_footer_3 {
  background-color: #0052CC !important;
}
.mobile_footer_4 {
  background-color: #006644 !important;
} */
.mobile_footer_3 {
  background-color: #e5fff7 !important;
}

.leftDivWrapper {
  display: flex;
  gap: 24px;
}

@media screen and (max-width: 1440px) {
  .group_svg {
    font-size: 18px !important;
  }

  .card_title {
    font-size: 10px;
  }
}

@media screen and (max-width: 1275px) {
  .card_title {
    font-size: 12px;
  }
}

@media screen and (max-width: 1200px) {
  .card_title {
    font-size: 8px;
  }
}

@media screen and (max-width: 780px) {
  .is_mobile {
    display: none;
  }

  .customer_wrapper {
    padding: 0px 0px !important;
  }

  .listing_detail_container {
    display: flex;
    flex-direction: column;
  }

  .group_svg {
    width: 40px;
    height: 20px;
  }

  .stats_card {
    border-top: none;
    padding-top: 0;
  }

  .topWrapper {
    display: block;
    padding: 10px;
  }

  .page_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #272522;
  }

  .listing_empty_wrapper {
    padding-top: 0px !important;
  }

  .cardsWrapper {
    padding: 10px 0px;
    width: 85vw;
  }
  .popover {
    right: -110px !important;
    z-index: 1000 !important;
  }

  .upload_container {
    width: 95%;
  }
  .listing_detail_container {
    gap: 12px;
    border-bottom: 1.5px solid #e7e6e4;
    margin: 0 -16px 0 -16px;
    padding: 0 16px 24px 16px;
  }

  .upload_sheet_container {
    width: 100%;
    padding: 10px;
  }

  .mobile_table_footer_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.85);
  }

  .modal {
    padding: 15px;
  }

  .moreContentModal {
    padding: 16px !important;
  }

  .modal_loading {
    margin: 30px 0 60px;
  }

  .sticky {
    padding-bottom: 0;
    position: relative;
  }

  .table_header {
    font-size: 18px;
  }

  .listing_container {
    max-width: 100%;
    width: 60%;
  }

  .hint {
    font-size: 12px;
  }

  .listing_modal_contaner {
    padding: 20.5px 16px 0px 16px;
    overflow: scroll;
  }

  .participants_flex_row {
    flex-direction: column;
    width: 100%;
  }

  .label {
    min-width: 100%;
  }

  .normal_black_text {
    word-wrap: normal;
  }

  .flex_row {
    padding-left: 15px;
  }

  .mobileParent form {
    padding-left: 15px !important;
  }
  .bookedSessionMobile {
    width: 42vw;
    min-width: 100px !important;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  .bookedInputMobile > div {
    background-color: #ffff !important;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
  }
  .bookedInputMobile > div::before {
    border-bottom: none !important;
  }
  .bookedInputMobile > div::after {
    border-bottom: none !important;
  }
  .bookedSessionMobile:after {
    border-bottom: unset !important;
  }
  .listItem {
    width: 100vw !important;
    padding: 0 !important;
  }
  .filterMobile {
    margin-top: 10px !important;
    margin-right: -5px !important;
    margin-bottom: 35px !important;
  }
  /* .listItem div div button{
        position: absolute;
        top: 258px;
        right: 311px;
    } */

  .bookedSessionMobile > div::before {
    border-bottom: none !important;
  }
  .bookedSessionMobile > div::after {
    border-bottom: none !important;
  }

  .bookedSessionMobile input {
    background-color: #ffff !important;
  }
  .bookedSessionMobile > div > div {
    background-color: #ffff !important;
  }
  .clearFilterMobile {
    padding-left: 15px;
  }
  .card {
    margin: 0 15px;
  }
  .whatsapp_number {
    white-space: normal;
  }
  .mobilemailBlock {
    display: flex;
  }
  .emailId {
    margin-left: 6px;
  }
  .listItem > div:nth-child(2) > div {
    border: none;
  }

  .bookedSessionMobile input {
    background-color: #ffff !important;
  }
  .bookedSessionMobile > div > div {
    background-color: #ffff !important;
  }
  .clearFilterMobile {
    padding-left: 15px;
  }
  .card {
    margin: 0 15px;
  }
  .whatsapp_number {
    white-space: normal;
  }
  .mobilemailBlock {
    display: flex;
  }
  .emailId {
    margin-left: 6px;
  }
  .listItem > div:nth-child(2) > div {
    border: none;
  }
  .bold_email {
    font-weight: 700;
    white-space: pre;
  }

  .listing_image {
    width: 85%;
    height: auto;
  }
  .offering_image {
    width: 119px;
    height: 112px;
    border-radius: 12px;
  }

  .bottom_margin {
    margin-bottom: 30px !important;
  }

  .mail_all {
    bottom: -10px;
    position: unset;
    margin: 5px 0 0 0 !important;
    width: 100%;
  }

  .listing_desc_container {
    margin-left: 0;
    margin-top: 12px;
  }

  .filterButtonContainer {
    display: block;
    margin-bottom: unset;
    margin-left: 15px;
  }

  .buttonfilter {
    margin: 20px 0;
  }

  .widthAdjust {
    width: 90%;
  }

  .listItem form {
    padding-top: 20px;
  }

  .table_heading {
    margin: 20px 15px;
  }
  .bottomDiv {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: 1.5px solid #e2e2e2;
    padding: 1rem;
    width: 100vw;
    z-index: 99;
    background-color: #ffffff;
  }
  .newBottomDiv {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 80px;
    height: 52px;
    box-sizing: border-box;
    left: 0;
    border-top: 1.5px solid #e2e2e2;
    padding: 8px 14px;
    width: 100vw;
    z-index: 99;
    background-color: #ffffff;
  }
  .flex_box {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .save_cta {
    background-color: #6c5dd3;
    padding: 7px 15px;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    justify-content: right;
    height: fit-content;
    width: 100% !important;
    cursor: pointer;
    text-align: center !important;
  }
  .bold_black_medium_text {
    font-size: 14px;
    color: black;
    font-weight: bold;
    margin: 0 6px 0 0;
    line-height: 1.33;
    padding-left: 0px !important;
  }
}

.popoverBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
}

.popover {
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0/0.1), 0 8px 10px -6px rgb(0 0 0/0.1);
  padding: 5px 0;
  position: absolute;
  right: -10px;
  top: 0;
  z-index: 1001;
  font-size: 14px;
  min-width: 300px;
}

.item {
  padding: 10px;
  border-bottom: 1px solid #f0eefb;
  color: rgba(0, 0, 0, 0.8) !important;
}

.item:hover {
  background-color: #fbfbfb;
  cursor: pointer;
}

.item:last-child {
  border-bottom: none;
}

.dispatched {
  background: #fff7e5 !important;
}

.pending {
  background: #fbebea !important;
}

.delivered {
  background: #e5fff7 !important;
}

@media screen and (max-width: 390px) {
  .listing_container {
    width: 100%;
  }
}
